import React, { useState } from 'react'
import classes from './Hero2.module.css'
import Typing from './Typing'
import NewPopup from '../popup/NewPopup';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

import spoon from './spoon.png'
import scooter from './scooter.png'

const Hero2 = () => {
    const [sp, setsp] = useState(false);
    const navigate = useNavigate();

    const loginSuccess = Cookies.get('CID');

    const showpopup = () => {

        if (loginSuccess) {
            setsp(true);
            //alert("We are only taking bulk orders (minimum quantity 20 orders. Call on customer care number) till 4th September 2024 (Not Taking Single Orders). Sorry for the inconvenience")
        } else {
            navigate('/login')
            //alert("We are only taking bulk orders (minimum quantity 20 orders. Call on customer care number) till 4th September 2024 (Not Taking Single Orders). Sorry for the inconvenience")
        }


    };

    const hidepopup = () => {
        setsp(false);
    };

    return (
        <div className={classes.container}>
            <div className={classes.hold1}>
                <div className={classes.spoonHold}>
                    <p className={classes.outline}>DLunch Bo</p>
                    <img src={spoon} className={classes.spoon}></img>
                    <img src={scooter} className={classes.scooter}></img>
                </div>

                <p className={classes.head}>Hungry? You are in the right place</p>
                <div><Typing></Typing></div>
                <p className='text-center'>We are not taking orders on 12th & 13th Oct. Other dates orders are open/</p>

                <button onClick={showpopup} className='text-white text-xl bg-green-700 px-8 py-4 rounded-full mx-auto block mt-8'>Online Order</button>

                {sp && <NewPopup onClose={hidepopup} />}
            </div>

        </div>
    )
}

export default Hero2;